/**
 * Substitutes data into a template.
 * A variable is referenced using ${variableName}
 * @param {string} template - a string containing variables
 * @param {object} vars - an object with properties matching the variable names
 * @returns {string} - the template with the variables substituted
 */
const substitute = (template, vars = {}) => {
    if (template === undefined) {
        return "";
    }

    if (vars === undefined) {
        return template;
    }

    const handler = new Function(
        "vars",
        [
            "const tagged = ( " + Object.keys(vars).join(", ") + " ) =>",
            "`" + template + "`",
            "return tagged(...Object.values(vars))",
        ].join("\n"),
    );

    try {
        return handler(vars);
    } catch (error) {
        console.log(error)
        return template;
    }
};

export default {
    substitute
}