import content from "./content";
import widgets from "./widgets";

/**
 * @typedef component
 * @type {object}
 * @property {string} name
 * @property {number} count
 * @property {properties=} props
 * @property {object=} dataSet 
 * @property {definition[]=} children
 */

/**
 * Represents a single item within a page
 * @typedef definition
 * @type {object}
 * @property {string} t - type of component
 * @property {data} d - data for the component
 * @property {properties} p - properties of the component
 * @property {definition[]} c - child definitions
 * @property {reference} r - reference file
 */

/**
 * Represents the data configuration of a component
 * @typedef data
 * @type {object}
 * @property {string} v - name of the data view
 */

/**
 * A reference file for the component type
 * @typedef reference
 * @property {string} name
 * @property {string} val
 */

/**
 * @typedef properties
 * @type {object}
 * @property {string} txt - text to display inside the component
 * @property {string} name - name to display for debugging
 */


/**
* Fetches a definition from the API
* @param {string} id - identifier for the definition
* @returns {Promise<definition[]>}
*/
async function fetchDefinitions(id) {
    return fetch(`/api/page/${id}`)
        .then((resp) => {
            return resp.json();
        })
        .catch((err) => {
            console.debug(err);
        });
}

/**
 * Searches for the names of data views recursively
 * @param {definition} def - definition of a component
 * @param {string[]} list - list to populate
 */
function searchForViews(def, list) {
    if (def.c) {
        def.c.forEach(function (child) {
            searchForViews(child, list)
        })
    }

    // if the data has a view name property
    if (def.d && def.d.v && def.d.v !== "") {
        list.push(def.d.v)
    }
}

/**
 * Finds the names of data views within any definition
 * @param {definition[]} defs - definitions to search
 * @returns {string[]} - list of data view names
 */
function findViews(defs) {
    /**
     * @type {string[]}
     */
    let views = []

    defs.forEach(function (comp) {
        searchForViews(comp, views)
    })

    return views
}

const definition = {
    /**
    * Loads a definition, loads all the referenced widgets
    * and content into stores for future loading
    * @param {string} id - identifier for the definition
    * @returns {Promise<definition[]>} - a list of definitions which define the component
    */
    load: async (id) => {
        const defs = await fetchDefinitions(id)
        await content.load(findViews(defs))
        await widgets.load(defs)
        return defs
    }
};

export default definition;