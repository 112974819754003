<script>
    import Component from "./component.svelte";
    import widgets from "./widgets.js";

    // widget.svelte

    /**
     * @type {import("./definition").definition}
     */
    export let def;

    let defs = [];

    if (def.r && def.r.val !== "") {
        widgets.fetch(def.r.val).then((store) => {
            store.subscribe((list) => {
                defs = [...list];
            });
        });
    }
</script>

{#each defs as d}
    <Component def={d} />
{/each}
