import template from "./template";

/**
 * Represents a single web element
 * @typedef element
 * @type {object}
 * @property {string} name
 * @property {string} text
 * @property {object} attrs
 * @property {boolean} selfClosing
 * @property {boolean} allowText
 * @property {boolean} isMetadata
 */

const elements = {
  title: { name: "title", isMetadata: true, allowText: true },
  box: { name: "div" },
  button: { name: "button", allowText: true },
  image: { name: "img", selfClosing: true },
  link: { name: "a" },
  list: { name: "ul" },
  item: { name: "li" },
  text: { name: "span", allowText: true },
  resource: { name: "link", isMetadata: true, selfClosing: true },
  description: { name: "meta", isMetadata: true, selfClosing: true, attrs: { name: "description" } }
};

const attrs = {
  styles: { name: "class" },
  desc: { name: "alt" },
  src: { name: "src" },
  addr: { name: "href", canTemplate: true },
  mime: { name: "type" },
  rel: { name: "rel" },
  content: { name: "content" }
};

const _web = { elements: elements, attributes: attrs }

/**
 * Maps component properties to web attributes
 * @param {import("./definition").properties} props 
 * @returns {object}
 */
function loadAttributes(props, data) {
  var result = {};
  if (props) {

    for (const [key, value] of Object.entries(props)) {
      if (key !== "txt" && value) {
        // get the attribute name from 
        // the component property name
        const attr = attrs[key];
        if (attr && value) {

          if (attr.canTemplate) {
            result[attr.name] = template.substitute(value, data);
          } else {
            result[attr.name] = value;
          }
        }
      }
    }
  }
  return result;
}

const web = {
  /**
   * Maps a component to a web element with attributes
   * @param {string} name - component name
   * @param {import("./definition").properties} props - component properties
   * @returns {element}
   */
  map: (name, props, data) => {
    const ele = _web.elements[name]

    // TODO: have the fallback be to show the text
    const txt = (props && props.txt) ? template.substitute(props.txt, data) : ""

    let attrs = loadAttributes(props, data)

    // add any default attrs from the element to the props
    if (ele.attrs) {
      for (const [key, value] of Object.entries(ele.attrs)) {
        attrs[key] = value
      }
    }
    return {
      name: ele.name,
      text: txt,
      attrs: attrs,
      selfClosing: ele.selfClosing ?? false,
      allowText: ele.allowText ?? false,
      isMetadata: ele.isMetadata ?? false
    }
  }
}

export default web