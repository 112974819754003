<script>
  import definition from "./definition";
  import Component from "./component.svelte";
  import Widget from "./widget.svelte";

  let id = document.getElementById("pageId").innerText;
</script>

{#await definition.load(id)}
  loading...
{:then definitions}
  {#each definitions as def}
    {#if def.t === "widget"}
      <Widget {def} />
    {:else}
      <Component {def} />
    {/if}
  {/each}
{/await}
