<script>
  import web from "./web";

  // element.svelte

  /** @type {string} */
  export let name;

  /** @type {import("./definition").properties} */
  export let props;

  /** @type {object} */
  export let data;

  /** Web element for the provided component name and props */
  let ele = {};
  ele = web.map(name, props, data);
</script>

<svelte:head>
  {#if ele.isMetadata}
    {#if ele.selfClosing}
      <svelte:element this={ele.name} {...ele.attrs} />
    {:else}
      <svelte:element this={ele.name} {...ele.attrs}>
        {#if ele.allowText && ele.text}
          {ele.text}
        {/if}
      </svelte:element>
    {/if}
  {/if}
</svelte:head>

{#if !ele.isMetadata}
  {#if ele.selfClosing}
    <svelte:element this={ele.name} {...ele.attrs} />
  {:else}
    <svelte:element this={ele.name} {...ele.attrs}>
      {#if ele.allowText && ele.text}
        {ele.text}
      {/if}
      <slot />
    </svelte:element>
  {/if}
{/if}
