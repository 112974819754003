import { writable } from "svelte/store";

const _views = {}

/**
 * Fetch the data for a component
 * @param {string} name - view name
 * @returns {Promise<object>}
 */
function fetchData(name) {
  return fetch(`/api/data`, {
    method: `POST`,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ view: name }),
  }).then((resp) => {
    return resp.json();
  }).catch((err) => {
    console.debug(err);
  });
}

const content = {
  /**
   * Fetches a store which will return 
   * the content
   * @param {string} name - rows of data
   * @returns {Promise}
   */
  fetch: async (name) => {
    const viewStore = _views[name]
    if (viewStore) {
      return viewStore
    }
    _views[name] = writable({})
    return _views[name]

  },
  /**
   * Loads content into stores for all the 
   * view names provided
   * @param {string[]} viewNames 
   */
  load: async (viewNames) => {
    if (viewNames && Array.isArray(viewNames) && viewNames.length > 0) {
      viewNames.forEach(name => {
        fetchData(name).then((data) => {
          if (_views[name]) {
            let store = _views[name]
            store.update(() => {
              return data
            })
            _views[name] = store
          } else {
            _views[name] = writable(data)
          }
        })
      });
    }
  }
};
export default content;
