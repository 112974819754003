<script>
  import content from "./content";
  import Element from "./element.svelte";

  // component.svelte

  /**
   * @type {import("./definition").definition}
   */
  export let def;

  /**
   * Data which can be used for display
   * in an element.
   * @type {object}
   */
  export let dataSet = {};

  /**
   * @type {import("./definition").component}
   */
  let comp = {
    name: "",
    count: 0,
  };

  // If the component is fetching a list of data
  // then each item within the list needs to be
  // provided the single row of data which
  // matches its index in the list.
  let isList = def.d && def.d.v && def.d.v !== "";

  if (def) {
    comp = {
      name: def.t,
      count: isList ? 0 : 1,
      props: def.p,
      dataSet: dataSet,
      children: def.c,
    };
  }

  if (isList) {
    const viewName = def.d.v;

    content.fetch(viewName).then((viewStore) => {
      viewStore.subscribe((rows) => {
        if (rows && Array.isArray(rows)) {
          comp.dataSet = [...rows];
          comp.count = rows.length;
        }
      });
    });
  }
</script>

{#each Array.from(Array(comp.count).keys()) as num (num)}
  <Element name={comp.name} props={comp.props} data={dataSet}>
    <slot>
      {#if comp.children}
        {#each comp.children as child}
          <svelte:self
            def={child}
            dataSet={isList ? comp.dataSet[num] : comp.dataSet}
          />
        {/each}
      {/if}
    </slot>
  </Element>
{/each}
