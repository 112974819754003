import { writable } from 'svelte/store'

const _widgets = {}

/**
 * Searches for the names of data views recursively
 * @param {import('./definition').definition} def - definition of a component
 * @param {string[]} list - list to populate
 */
function searchForWidgets(def, list) {
  if (def.c) {
    def.c.forEach(function (child) {
      searchForWidgets(child, list)
    })
  }

  // if the data has a view name property
  if (def.t && def.t === "widget" && def.r && def.r.val !== "") {
    list.push(def.r.val)
  }
}

/**
 * Finds the names of widgets within any definition
 * @param {import('./definition').definition[]} defs - definitions to search
 * @returns {string[]} - list of widget names
 */
function findWidgets(defs) {
  /**
     * @type {string[]}
     */
  let widgets = []

  defs.forEach(function (def) {
    searchForWidgets(def, widgets)
  })

  return widgets
}

/**
 * Fetches the definitions for a widget
 * @param {string} name - widget name
 * @returns {Promise<import('./definition').definition>}
 */
function fetchWidget(name) {
  return fetch(`/api/widget/${name}`)
    .then((resp) => {
      return resp.json();
    })
    .catch((err) => {
      console.debug(err);
    });
}

const widgets = {
  /**
   * Fetches a widget from the local store
   * @param {string} name - name of a widget
   * @returns 
   */
  fetch: async (name) => {
    const widgetStore = _widgets[name]

    if (widgetStore) {
      return widgetStore
    }
    _widgets[name] = writable([])

    return _widgets[name]
  },
  /**
   * Loads the widgets from the API into individual local stores
   * @param {import('./definition').definition[]} defs - list of widget names
   */
  load: async (defs) => {

    let names = findWidgets(defs)

    names.forEach((name) => {
      fetchWidget(name).then((definitions) => {

        // if there's a store for the widget then
        // update the store with the definitions
        // which make up the widget
        if (_widgets[name]) {
          let store = _widgets[name]
          store.update(() => {
            return definitions
          })
          _widgets[name] = store
        } else {
          // otherwise create an object property
          // using the widget's name and set it
          // to the definitions which make up
          // the widget
          _widgets[name] = writable(definitions)
        }
      })
    })
  },
}



export default widgets